import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../assets/images/logos/logo.png';

import emailImg from '../../assets/images/icons/contacts/email.png';
import telegramImg from '../../assets/images/icons/contacts/telegram.png';
import whatsappImg from '../../assets/images/icons/contacts/whatsapp.png';

import facebook from '../../assets/images/icons/social/facebook.png';
import instagram from '../../assets/images/icons/social/instagram.png';
import twitter from '../../assets/images/icons/social/twitter.png';
import youtube from '../../assets/images/icons/social/youtube.png';

import androidImg from '../../assets/images/icons/download/android.png';
import appleImg from '../../assets/images/icons/download/apple.png';
import lgImg from '../../assets/images/icons/download/lg.png';
import samsungImg from '../../assets/images/icons/download/samsung.png';
import windowsImg from '../../assets/images/icons/download/windows.png';

import request from '../../service/request';

const Footer = (props) => {
    const { footerLogo, footerClass } = props;

    const [contacts, setContacts] = useState({
        email: "",
        telegram: "",
        whatsapp: "",
        youtube: "",
        facebook: "",
        instagram: "",
        twitter: "",
    });

    const [appUrl, setAppUrl] = useState({
        "samsung": "",
        "lg": "",
        "windows": "",
        "android": "",
        "ios": "",
        "macos": ""
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await request.read({ entity: "config", jsonData: { ids: ["latest_version", "support_contacts"] } });
                if (result) {
                    setContacts(result?.support_contacts);
                    const urls = {};
                    result.latest_version.forEach((obj) => {
                        urls[obj.app_type] = obj?.url;
                    });
                    setAppUrl(urls);

                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);
    return (
        <>
            <footer id="react-footer" className={footerClass ? footerClass : 'react-footer home-main'}>
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 md-mb-30">
                                <div className="footer-widget footer-widget-1">
                                    <div className="footer-logo white">
                                        <Link to="/" className="logo-text"><img src={footerLogo ? footerLogo : Logo} alt="Thunder Player" style={{ height: "70px" }} /></Link>
                                    </div>
                                    <h5 className="footer-subtitle">Thunder Player provides an exceptional TV streaming experience through their Thunder Player app. Offering the ultimate convenience, the app allows users to swiftly load their IPTV playlists and enjoy their favorite channels across a variety of platforms, including Samsung, LG, Windows, Android, and iOS/macOS. Activate the app now for an unparalleled streaming experience. Download it today!</h5>
                                    <div className="react-copy-left">© 2024 <Link to="/">Thunder Player.</Link> All Rights Reserved</div>
                                </div>
                            </div>
                            <div className="col-lg-3 md-mb-30">
                                <div className="footer-widget footer-widget-2">
                                    {/* <h3 className="footer-title">About Us</h3> */}
                                    <div className="footer-menu">
                                        <ul>
                                            <li><Link to="/about-us">About</Link></li>
                                            <li><Link to="/contact">Contact</Link></li>
                                            <li><Link to="/terms-and-conditions">Terms and Conditions</Link></li>
                                            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                            <li><Link to="/faq">FAQ</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="footer-widget footer-widget-4">
                                    <div>
                                        {/* <ul className="footer-address mb-2">
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg><a href="mailto:support@thundertv.com"> {contacts?.email} </a></li>
                                        </ul> */}
                                        <p className='mb-3'>Contact Us</p>
                                        <ul className='flex gap-2'>
                                            <li>
                                                <a target="_blank" href={"mailto:" + contacts?.email} className='support-item'><em className="icon"><img src={emailImg} alt="image" /></em></a>
                                            </li>
                                            <li>
                                                <a target="_blank" href={"https://t.me/" + contacts?.telegram} className='support-item'><em className="icon"><img src={telegramImg} alt="image" /></em></a>
                                            </li>
                                            <li>
                                                <a target="_blank" href={"https://api.whatsapp.com/send/?phone=" + contacts?.whatsapp} className='support-item'><em className="icon"><img src={whatsappImg} alt="image" /></em></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="mt-4">
                                        <p className='mb-3'>Follow Us</p>
                                        <ul className='flex gap-2'>
                                            <li>
                                                <a target="_blank" href={"https://www.youtube.com/@" + contacts?.youtube} className='support-item'><em className="icon"><img src={youtube} alt="image" /></em></a>
                                            </li>
                                            <li>
                                                <a target="_blank" href={"https://www.facebook.com/" + contacts?.facebook} className='support-item'><em className="icon"><img src={facebook} alt="image" /></em></a>
                                            </li>
                                            <li>
                                                <a target="_blank" href={"https://www.instagram.com/" + contacts?.instagram} className='support-item'><em className="icon"><img src={instagram} alt="image" /></em></a>
                                            </li>
                                            <li>
                                                <a target="_blank" href={"https://twitter.com/" + contacts?.twitter} className='support-item'><em className="icon"><img src={twitter} alt="image" /></em></a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="mt-4">
                                        <p className='mb-3'>Download</p>
                                        <ul className='flex gap-2'>
                                            <li>
                                                <a target="_blank" href={appUrl?.samsung ?? "#"} className='support-item'><em className="icon"><img src={samsungImg} alt="image" /></em></a>
                                            </li>
                                            <li>
                                                <a target="_blank" href={appUrl?.lg ?? "#"} className='support-item'><em className="icon"><img src={lgImg} alt="image" /></em></a>
                                            </li>
                                            <li>
                                                <a target="_blank" href={appUrl?.windows ?? "#"} className='support-item'><em className="icon"><img src={windowsImg} alt="image" /></em></a>
                                            </li>
                                            <li>
                                                <a target="_blank" href={appUrl?.android ?? "#"} className='support-item'><em className="icon"><img src={androidImg} alt="image" /></em></a>
                                            </li>
                                            <li>
                                                <a target="_blank" href={appUrl?.ios ?? "#"} className='support-item'><em className="icon"><img src={appleImg} alt="image" /></em></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="copyright">
                    <div className="container">
                        <div className="react-copy-left">© 2024 <Link to="/">Thunder Player.</Link> All Rights Reserved</div>
                        <div className="react-copy-right">
                            <ul className="social-links">
                                <li className="follow">Follow us</li>
                                <li><Link to="#"><span aria-hidden="true" className="social_facebook"></span></Link></li>
                                <li><Link to="#"><span aria-hidden="true" className="social_twitter"></span></Link></li>
                                <li><Link to="#"><span aria-hidden="true" className="social_linkedin"></span></Link></li>
                            </ul>
                        </div>
                    </div>
                </div> */}
            </footer>
        </>
    );
}

export default Footer;