import { toast } from "react-toastify";

import * as authService from '../../service/auth';
import * as actionTypes from './types';

export const login =
  ({ loginData }) =>
    async (dispatch) => {
      dispatch({
        type: actionTypes.LOADING_REQUEST,
        payload: { loading: true },
      });
      try {
        const data = await authService.login({ loginData });

        if (data && data.success === true) {
          //console.log('%redux\auth\actions.js:16 data.result', 'color: #007acc;', data.result);
          window.localStorage.setItem('isLoggedIn', true);
          window.localStorage.setItem('token', JSON.parse(data.result.token));
          window.localStorage.setItem('auth', JSON.stringify(data.result.device));
          dispatch({
            type: actionTypes.LOGIN_SUCCESS,
            payload: data.result.device,
          });
          toast.success("Login successful!");
          // history.push('/');
          // window.location.href = '/dashboard';
        } else {
          dispatch({
            type: actionTypes.FAILED_REQUEST,
            payload: data,
          });
          toast.error(data?.message);
        }
      } catch (error) {

        dispatch({
          type: actionTypes.FAILED_REQUEST,
          payload: null,
        });
        toast.error("An error occurred. Please try again.");
      }
    };

export const logout = () => async (dispatch) => {
  authService.logout();
  dispatch({
    type: actionTypes.LOGOUT_SUCCESS,
  });
  // history.push('/login');
  window.location.href = window.location.origin
  toast.success("Logout successful!");
};
