import { Buffer } from 'buffer';

export const encrypt = async (src) => {
  try {
    //console.log('%csrc\redux\auth\actions.js:5 encrypt original request', 'color: red;', src);
    const allowedCharacters = availableChars();
    const base64 = Buffer.from(JSON.stringify(src), 'utf-8').toString('base64');

    let base_length = 42;
    if ((base64.length < base_length)) base_length = base64.length - 1;
    const pos = Math.floor(Math.random() * base_length) + 2;
    const length = Math.floor(Math.random() * allowedCharacters.length);
    const char1 = allowedCharacters.charAt(pos);
    const char2 = allowedCharacters.charAt(length);

    const encoded = base64.substring(0, pos) + randomString(length) + base64.substring(pos) + char1 + char2;
    const result = JSON.stringify({ data: encoded });
    //console.log('%csrc\redux\auth\actions.js:16 encrypt encoded result', 'color: green;', result);

    return result;
  } catch (error) {
    // return errorHandler(error);
  }
};

export const decrypt = async (response) => {
  try {
    const { data: encoded } = response;
    if (!encoded) {
      return {
        success: false,
        result: null,
        message: 'Oops there is an Error',
      };

    }
    const allowedCharacters = availableChars();

    const length = encoded.length;
    const pos = allowedCharacters.indexOf(encoded.charAt(length - 2));
    const char1 = allowedCharacters.indexOf(encoded.charAt(length - 1));

    const raw = encoded.substring(0, length - 2);
    const part1 = raw.substring(0, pos);
    const part2 = raw.substring(pos + char1);

    const decodedBase64 = Buffer.from(part1 + part2, 'base64').toString('utf-8');
    //console.log('%csrc\redux\auth\actions.js:49 decrypt encoded response', 'color: red;', encoded);
    //console.log('%csrc\redux\auth\actions.js:50 decrypt decoded response', 'color: green;', JSON.parse(decodedBase64));

    return JSON.parse(decodedBase64);
  } catch (error) {
    return {
      success: false,
      result: null,
      error: error,
      message: 'Oops there is an Error',
    };
  }
};


const availableChars = () => {
  return 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
};

const randomString = (length) => {
  const allowedCharacters = availableChars();
  let str = '';

  for (let i = 0; i < length; i++) {
    const pos = Math.floor(Math.random() * allowedCharacters.length);
    str += allowedCharacters.charAt(pos);
  }

  return str;
}