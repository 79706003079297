import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa6";
import { Link } from "react-router-dom";
import aboutImg from '../../assets/images/about/ab.png';

import { Download } from "@mui/icons-material";
import request from '../../service/request';

import androidImg from '../../assets/images/icons/download/android.png';
import appleImg from '../../assets/images/icons/download/apple.png';
import lgImg from '../../assets/images/icons/download/lg.png';
import samsungImg from '../../assets/images/icons/download/samsung.png';
import windowsImg from '../../assets/images/icons/download/windows.png';
import { API_URL } from "../../utils/environment";

const DownloadButton = ({ href, icon, label, style }) => (
    <a target="_blank" href={href} className="download-button flex items-center justify-center space-x-2" style={style}>
        {icon} {label}
    </a>
);

const DownloadMain = (props) => {

    const { type } = props;

    const [appLable, setAppLable] = useState("Android");
    const [appIcon, setAppIcon] = useState(windowsImg);

    const appList = [
        { type: "samsung", label: "Samsung TV", icon: <img src={samsungImg} alt="image" style={{ width: "30px", marginTop: "5px", marginBottom: "5px", marginRight: 20 }} /> },
        { type: "lg", label: "LG TV", icon: <img src={lgImg} alt="image" style={{ width: "30px", marginTop: "5px", marginBottom: "5px", marginRight: 20 }} /> },
        { type: "android", label: "Android", icon: <img src={androidImg} alt="image" style={{ width: "30px", marginRight: 20, padding: "5px 0px" }} /> },
        { type: "ios", label: "iOS/tvOS", icon: <img src={appleImg} alt="image" style={{ width: "30px", marginRight: 20, padding: "5px 0px" }} /> },
        { type: "windows", label: "Windows", icon: <img src={windowsImg} alt="image" style={{ width: "30px", marginRight: 20, padding: "5px 0px" }} /> },
    ]

    useEffect(() => {
        setAppLable(appList.find((item) => (item.type === type))?.label);
        setAppIcon(appList.find((item) => (item.type === type))?.icon);
        document.title = "Thunder Player | Download";
    }, [type]);

    const [appUrl, setAppUrl] = useState({
        "samsung": "",
        "lg": "",
        "windows": "",
        "android": "",
        "ios": "",
        "macos": ""
    });
    const [appFile, setAppFile] = useState({
        "samsung": "",
        "lg": "",
        "windows": "",
        "android": "",
        "ios": "",
        "macos": ""
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await request.read({ entity: "config", jsonData: { ids: ["latest_version"] } });
                if (result) {

                    const urls = {};
                    const files = {};
                    result.latest_version.forEach((obj) => {
                        urls[obj.app_type] = obj?.url ? obj?.url : "";
                        files[obj.app_type] = obj?.file ? obj?.file : "";
                    });
                    setAppUrl(urls);
                    setAppFile(files);

                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);
    return (
        <>
            <div className="download-tab__content mb---90 mt---90">
                <div className="google-tab">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                                    <img src={aboutImg} alt="About" />
                                </div>
                            </div>
                            <div className="col-lg-6 pl---50 pt---30">
                                <div className="about__content">
                                    <h3 className="about__title wow animate__fadeInUp text-3xl mb-4" data-wow-duration="0.3s">The best Thunder Player for {appLable}</h3>
                                    <ul className="app-description">
                                        <li className="flex items-center"><FaCheck className="check" />Register for free</li>
                                        <li className="flex items-center"><FaCheck className="check" />Add your playlists</li>
                                        <li className="flex items-center"><FaCheck className="check" />Activate your account</li>
                                        <li className="flex items-center"><FaCheck className="check" />Fast Streaming</li>
                                    </ul>
                                    {appUrl[type] == "" && appFile[type] == "" && (
                                        <div className="pt---30">
                                         <h5> {appLable} application  is not ready yet. We will publish the application soon</h5>
                                        </div>
                                    )}
                                    <div className="mt---50 grid grid-cols-1 sm:grid-cols-2 gap-3">
                                        {type === "samsung" && (
                                            <>
                                                {appUrl[type] && (
                                                    <DownloadButton
                                                        href={appUrl[type] ?? "#"}
                                                        icon={appIcon}
                                                        label="Install from Samsung Store"
                                                    />
                                                )}
                                                {appFile[type] && (
                                                    <DownloadButton
                                                        href={`${API_URL}download/samsung`}
                                                        icon={<Download style={{ marginRight: 20 }} />}
                                                        label="Download Widget File"
                                                        style={{ backgroundColor: 'blue' }}
                                                    />
                                                )}
                                            </>
                                        )}

                                        {type === "android" && (
                                            <>
                                                {appUrl[type] && (
                                                    <DownloadButton
                                                        href={appUrl[type] ?? "#"}
                                                        icon={appIcon}
                                                        label="Install from Playstore"
                                                    />
                                                )}
                                                {appFile[type] && (
                                                    <DownloadButton
                                                        href={`${API_URL}download/android`}
                                                        icon={<Download style={{ marginRight: 20 }} />}
                                                        label="Download APK File"
                                                        style={{ backgroundColor: 'blue' }}
                                                    />
                                                )}
                                            </>
                                        )}

                                        {type === "windows" && (
                                            <>
                                                {appUrl[type] && (
                                                    <DownloadButton
                                                        href={appUrl[type] ?? "#"}
                                                        icon={appIcon}
                                                        label="Install from Microsoft Store"
                                                    />
                                                )}
                                                {appFile[type] && (
                                                    <DownloadButton
                                                        href={`${API_URL}download/windows`}
                                                        icon={<Download style={{ marginRight: 20 }} />}
                                                        label="Download EXE File"
                                                        style={{ backgroundColor: 'blue' }}
                                                    />
                                                )}
                                            </>
                                        )}

                                        {type === "ios" && appUrl[type] && (
                                            <DownloadButton
                                                href={appUrl[type] ?? "#"}
                                                icon={appIcon}
                                                label="Install from AppStore"
                                            />
                                        )}

                                        {type === "lg" && appUrl[type] && (
                                            <DownloadButton
                                                href={appUrl[type] ?? "#"}
                                                icon={appIcon}
                                                label="Install from LG Store"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DownloadMain;